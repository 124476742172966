<template>
  <div>
   <base-header class="pb-1">
       <div class="row align-items-center py-4">
        <div class="col-lg-6 col-7">
          <h6 class="h2 text-white d-inline-block mb-0"></h6>
        </div>
      </div>
    </base-header>

     <div class="container-fluid mt--6">
        <form>
              <div class="col-md-12">
                <card>
                  <h3 slot="header" class="mb-0">Motivos de Cancelamento</h3>
                  <div class="row">
                      <div class="col-md-9">
                          <div class="form-group">
                              <label>Filtro</label>
                              <input type="text" class="form-control" v-model="name" placeholder="Descrição"/>  
                          </div>
                      </div>    
                      <div class="col-md-6">    
                            <base-button type="primary" @click="findReason">Buscar</base-button>  
                            <base-button type="secondary" @click="clear">Limpar</base-button>       
                      </div> 
                  </div>   
                  <div class="col-md-12 text-right mb-3 pr-5">
                        <base-button type="neutral" icon size="sm" @click="newReason">
                            <span class="btn-inner--icon"><i class="fas fa-wallet"></i></span>
                            <span class="btn-inner--text">Novo Motivo</span>
                        </base-button>
                  </div>   
                  <table class="table">
                      <thead class="thead">
                          <tr>
                              <th scope="col">Descrição</th>
                              <th scope="col" width="100">Ativo?</th>
                              <th scope="col" width="100"></th>
                          </tr>
                      </thead>
                      <tbody>
                          <tr v-for="reason in reasons" :key="reason.id">
                              <td><a href="javascript:void(0);" @click.prevent="open(reason.id)">{{reason.description}}</a></td>
                              <td class="text-center">
                                  <span v-if="reason.isActive">Sim</span>
                                  <span v-if="!reason.isActive">Não</span>
                              </td> 
                              <td>
                                  <a href="#" title="Alterar Motivo" @click="open(reason.id)"><i class="far fa-folder-open"></i></a>
                                  <a href="#" title="Excluir Motivo" @click.prevent="deleteReason(reason.id)" class="ml-2"><i class="fas fa-trash-alt"></i></a>
                              </td>
                          </tr>
                          <tr v-show="reasons.length == 0">
                              <td colspan="8">
                                <center>Nenhum motivo disponível.</center>
                              </td>
                          </tr>
                      </tbody>
                  </table>
                </card> 
              </div>   

        </form>   
   </div> <!--- fecha conteudo -->  

  </div>
</template>
<script>
export default {
  components: {
  },
  mounted(){
     this.loadReason();      
  },
  data() {
    return {
       name: null,
       reasons : []
    };
  },
  methods:{
    newReason(){
        location.href = '/configuracoes/motivos/novo'
    },
    open(id){
        location.href = '/configuracoes/motivos/editar/'+id
    },
    deleteReason(id){
        const options = {title: 'Excluir Motivo de Cancelamento', okLabel: 'Sim', cancelLabel: 'Não'}
        this.$dialogs.confirm("Deseja excluir esse motivo?", options)  
           .then(res => {
                if(res.ok){
                    this.$clubApi.delete('/support/reason/delete/'+ id).then((response) => {
                        this.$notify({type: 'success', message: "Motivo do cancelamento excluído com sucesso"})
                        this.clear();
                    }).catch((error) => {
                        this.$notify({type: 'warning', message: error.response.data.msg})
                    }).finally(() =>{
                        NProgress.done() 
                    })
                }
           });
    },
    findReason(){
        this.$clubApi.get('/support/reason/find/'+ this.name)
        .then((response) => {
            this.reasons = response.data.object
        }) .catch((error) => {
            this.$notify({type: 'warning', message: error.response.data.msg})
        }).finally(() =>{
            NProgress.done() 
        })
    },
    loadReason(){
        this.$clubApi.get('/support/reason')
        .then((response) => {
            this.reasons = response.data.object
        }) .catch((error) => {
            this.$notify({type: 'warning', message: error.response.data.msg})
        }).finally(() =>{
            NProgress.done() 
        })
    },
	clear() {
        this.name = null,  
        this.loadReason();
		
	}
  }
};
</script>

<style></style>
